<template>
  <div>
    <b-row>
      <b-col sm="12">
        <div :class="animation">
          <fieldset v-show="stepper === 1">
            <b-card>
              <b-row>
                <b-col
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group label="Type">
                    <b-form-select
                      v-model="$v.payment.provider.$model"
                      :options="providerOptions"
                      :class="{
                        'is-invalid': $v.payment.provider.$error,
                      }"
                    />
                    <div
                      v-if="$v.payment.provider.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.provider.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group label="Dénomination">
                    <b-form-input
                      v-model="$v.payment.libelle.$model"
                      type="text"
                      :class="{
                        'is-invalid': $v.payment.libelle.$error,
                      }"
                      :formatter="formatter"
                    />
                    <div
                      v-if="$v.payment.libelle.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.libelle.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- Sigle -->
                <b-col
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group label="Sigle">
                    <b-form-input
                      v-model="$v.payment.sigle.$model"
                      type="text"
                      :class="{
                        'is-invalid': $v.payment.sigle.$error,
                      }"
                      :formatter="formatter"
                    />
                    <div
                      v-if="$v.payment.sigle.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.sigle.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- Dev Code -->
                <b-col
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group label="Dev Code">
                    <b-form-input
                      v-model="$v.payment.dev_code.$model"
                      type="text"
                      :class="{
                        'is-invalid': $v.payment.dev_code.$error,
                      }"
                      :formatter="formatter"
                    />
                    <div
                      v-if="$v.payment.dev_code.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.dev_code.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- Image -->
                <b-col
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group label="Image">
                    <b-form-file
                      v-model="$v.payment.file.$model"
                      :class="{
                        'is-invalid': $v.payment.file.$error,
                      }"
                      browse-text="Parcourir"
                      placeholder="Choisir le fichier"
                      accept=".jpg, .jpeg, .png, .svg"
                      @change="uploadFile($event)"
                    />
                    <b-progress
                      v-if="loadImage"
                      variant="primary"
                      height="3px"
                      :value="progress"
                      :max="100"
                      class="w-100 mt-1"
                    />
                    <div
                      v-if="$v.payment.file.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.file.required">L'image est obigatoire<br></span>
                      <span v-if="!$v.payment.file.checkSize">Taille maximale: 5MB</span>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- Devise -->
                <b-col
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group label="Devise">
                    <b-form-select
                      v-model="$v.payment.base_currency_symbol.$model"
                      :options="currenciesOptions"
                      :class="{
                        'is-invalid': $v.payment.base_currency_symbol.$error,
                      }"
                    />
                    <div
                      v-if="$v.payment.base_currency_symbol.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.base_currency_symbol.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- Vente -->
                <b-col
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group label="Prix de vente">
                    <b-form-input
                      v-model.number="$v.payment.sale.$model"
                      type="number"
                      :class="{
                        'is-invalid': $v.payment.sale.$error,
                      }"
                    />
                    <div
                      v-if="$v.payment.sale.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.sale.required">{{
                        $t("errors.required")
                      }}</span>
                      <span v-if="!$v.payment.sale.minValue">{{
                        $t("errors.minSaleValue")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- Achat -->
                <b-col
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <b-form-group label="Prix d'achat">
                    <b-form-input
                      v-model.number="$v.payment.buy.$model"
                      type="number"
                      :class="{
                        'is-invalid': $v.payment.buy.$error,
                      }"
                    />
                    <div
                      v-if="$v.payment.buy.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.buy.required">{{
                        $t("errors.required")
                      }}</span>
                      <span v-if="!$v.payment.buy.minValue">{{
                        $t("errors.minBuyValue")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- Code Dev -->
              </b-row>
              <b-row>
                <b-col>
                  <b-button
                    variant="secondary"
                    class="float-left"
                    @click="reset"
                  >
                    {{ $t("form.refresh") }}
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    class="float-right"
                    :disabled="loadImage"
                    @click="stepOne"
                  >
                    {{ $t("form.next") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </fieldset>

          <fieldset v-show="stepper === 2">
            <b-card>
              <b-row>
                <b-col sm="6">
                  <b-form-group label="Pourcentage Client">
                    <b-form-input
                      v-model.number="$v.payment.partner_percent.$model"
                      :class="{
                        'is-invalid': $v.payment.partner_percent.$error,
                      }"
                      type="number"
                    />
                    <div
                      v-if="$v.payment.partner_percent.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.partner_percent.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- Sigle -->
                <b-col sm="6">
                  <b-form-group label="Pourcentage Systeme">
                    <b-form-input
                      v-model.number="$v.payment.system_percent.$model"
                      :class="{
                        'is-invalid': $v.payment.system_percent.$error,
                      }"
                      type="number"
                    />
                    <div
                      v-if="$v.payment.system_percent.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.system_percent.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- Cash In -->
                <b-col sm="6">
                  <b-form-group label="Cash In">
                    <b-form-input
                      v-model="$v.payment.cashin_service_id.$model"
                      type="text"
                      :class="{
                        'is-invalid': $v.payment.cashin_service_id.$error,
                      }"
                      :formatter="formatter"
                    />
                    <div
                      v-if="$v.payment.cashin_service_id.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.cashin_service_id.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- Cash Out -->
                <b-col sm="6">
                  <b-form-group label="Cash Out">
                    <b-form-input
                      v-model="$v.payment.cashout_service_id.$model"
                      type="text"
                      :class="{
                        'is-invalid': $v.payment.cashout_service_id.$error,
                      }"
                      :formatter="formatter"
                    />
                    <div
                      v-if="$v.payment.cashout_service_id.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.cashout_service_id.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- Fees In-->
                <b-col sm="6">
                  <b-form-group label="Frais de transaction Depot">
                    <b-form-input
                      v-model.number="$v.payment.partner_fees_in.$model"
                      type="number"
                      :class="{
                        'is-invalid': $v.payment.partner_fees_in.$error,
                      }"
                    />
                    <div
                      v-if="$v.payment.partner_fees_in.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.partner_fees_in.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- Fees Out-->
                <b-col sm="6">
                  <b-form-group label="Frais de transaction Retrait">
                    <b-form-input
                      v-model.number="$v.payment.partner_fees_out.$model"
                      type="number"
                      :class="{
                        'is-invalid': $v.payment.partner_fees_out.$error,
                      }"
                    />
                    <div
                      v-if="$v.payment.partner_fees_out.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.partner_fees_out.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- Vente -->
                <b-col sm="3">
                  <b-form-group label="A vendre ?">
                    <b-form-checkbox
                      v-model="payment.sale_status"
                      :value="true"
                      :unchecked-value="false"
                    >
                      <span>{{ payment.sale_status ? 'Oui' : 'Non' }}</span>
                    </b-form-checkbox>
                    <div
                      v-if="$v.payment.sale_status.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.sale_status.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- Achat -->
                <b-col sm="3">
                  <b-form-group label="A acheter ?">
                    <b-form-checkbox
                      v-model="payment.buy_status"
                      :value="true"
                      :unchecked-value="false"
                    > <span>{{ payment.buy_status ? 'Oui' : 'Non' }}</span>
                    </b-form-checkbox>
                    <div
                      v-if="$v.payment.buy_status.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.buy_status.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>

                <!-- Transaction mobile -->
                <b-col sm="3">
                  <b-form-group label="Mobile ?">
                    <b-form-checkbox
                      v-model="payment.ismobile"
                      :value="true"
                      :unchecked-value="false"
                    >
                      <span>{{ payment.ismobile ? 'Oui' : 'Non' }}</span>
                    </b-form-checkbox>
                    <div
                      v-if="$v.payment.ismobile.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.ismobile.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- Transaction automatique ? -->
                <b-col sm="3">
                  <b-form-group label="Automatique ?">
                    <b-form-checkbox
                      v-model="payment.is_auto"
                      :value="true"
                      :unchecked-value="false"
                    >
                      <span>{{ payment.is_auto ? 'Oui' : 'Non' }}</span>
                    </b-form-checkbox>
                    <div
                      v-if="$v.payment.is_auto.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.is_auto.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- Vente automatique -->
                <b-col sm="3">
                  <b-form-group label="Vente automatique ?">
                    <b-form-checkbox
                      v-model="payment.sale_auto"
                      :value="true"
                      :unchecked-value="false"
                    >
                      <span>{{ payment.sale_auto ? 'Oui' : 'Non' }}</span>
                    </b-form-checkbox>
                    <div
                      v-if="$v.payment.sale_auto.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.sale_auto.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- Achat automatique ? -->
                <b-col sm="3">
                  <b-form-group label="Achat automatique ?">
                    <b-form-checkbox
                      v-model="payment.buy_auto"
                      :value="true"
                      :unchecked-value="false"
                    >
                      <span>{{ payment.buy_auto ? 'Oui' : 'Non' }}</span>
                    </b-form-checkbox>
                    <div
                      v-if="$v.payment.buy_auto.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.buy_auto.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group label="OTP ?">
                    <b-form-checkbox
                      v-model="payment.has_otp"
                      :value="true"
                      :unchecked-value="false"
                    >
                      <span>{{ payment.has_otp ? 'Oui' : 'Non' }}</span>
                    </b-form-checkbox>
                    <div
                      v-if="$v.payment.has_otp.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.has_otp.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col sm="3">
                  <b-form-group label="Crypto ?">
                    <b-form-checkbox
                      v-model="payment.is_crypto"
                      :value="true"
                      :unchecked-value="false"
                    >
                      <span>{{ payment.is_crypto ? 'Oui' : 'Non' }}</span>
                    </b-form-checkbox>
                    <div
                      v-if="$v.payment.is_crypto.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.payment.is_crypto.required">{{
                        $t("errors.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="text-center mb-2">
                    <b-spinner
                      v-if="process"
                      variant="primary"
                    />
                  </div>
                  <b-button
                    variant="secondary"
                    class="float-left"
                    :disabled="process"
                    @click="prev"
                  >
                    {{ $t("form.prev") }}
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    class="float-right"
                    :disabled="process"
                    @click="stepTwo"
                  >
                    <span>{{ $t("form.save") }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </fieldset>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import firebase from 'firebase/app'
import { v4 as uuidv4 } from 'uuid'
import encrypt from '@/libs/encrypt'
import 'firebase/storage'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      animation: null,
      stepper: 1,
      loadImage: false,
      progress: 0,
      payment: {
        libelle: null,
        sale: null,
        buy: null,
        sigle: null,
        dev_code: null,
        ismobile: false,
        sale_status: false,
        buy_status: false,
        is_auto: true,
        partner_percent: null,
        partner_fees_in: 0,
        partner_fees_out: 0,
        system_percent: null,
        cashin_service_id: null,
        cashout_service_id: null,
        file: null,
        fileName: null,
        has_otp: false,
        buy_auto: false,
        sale_auto: false,
        is_crypto: false,
        base_currency_symbol: null,
        provider: null,
      },
      providerOptions: [
        { value: null, text: 'Sélectionner' },
        { value: 'COINBASE', text: 'COINBASE' },
        { value: 'COINPAYMENT', text: 'COINPAYMENT' },
        { value: 'INTOUCH', text: 'INTOUCH' },
        { value: 'PAYEER', text: 'PAYEER' },
        { value: 'PERFECT', text: 'PERFECT' },
        { value: 'DEFAULT', text: 'DEFAULT' },
      ],
      currenciesOptions: [
        { value: null, text: 'Sélectionner' },
        { value: 'XOF', text: 'XOF' },
        { value: 'GNF', text: 'GNF' },
        { value: 'USD', text: 'USD' },
        { value: 'EURO', text: 'EURO' },
        { value: 'BTC', text: 'BTC' },
        { value: 'BCH', text: 'BCH' },
        { value: 'BNB', text: 'BNB' },
        { value: 'BNB.BSC', text: 'BNB.BSC' },
        { value: 'BNB.BEP20', text: 'BNB.BEP20' },
        { value: 'BUSD.BEP20', text: 'BUSD.BEP20' },
        { value: 'SHIB.BEP20', text: 'SHIB.BEP20' },
        { value: 'USDT.TRC20', text: 'USDT.TRC20' },
        { value: 'TRX', text: 'TRX' },
        { value: 'DOGE', text: 'DOGE' },
      ],
    }
  },
  validations: {
    payment: {
      libelle: {
        required,
      },
      fileName: {
        required,
      },
      is_crypto: {
        required,
      },
      provider: {
        required,
      },
      sale: {
        required,
        minValue: minValue(0),
      },
      buy: {
        required,
        minValue: minValue(0),
      },
      sigle: {
        required,
      },
      dev_code: {
        required,
      },
      base_currency_symbol: {
        required,
      },
      ismobile: {
        required,
      },
      sale_status: {
        required,
      },
      buy_status: {
        required,
      },
      is_auto: {
        required,
      },
      partner_percent: {
        required,
      },
      system_percent: {
        required,
      },
      cashin_service_id: {
        required,
      },
      cashout_service_id: {
        required,
      },
      file: {
        required,
        checkSize() {
          if (this.payment.file != null) {
            const filesize = this.payment.file.size
            const file = Math.round(filesize / 1024 / 1024)
            if (file <= 5) {
              return true
            }
            return false
          }
          return ''
        },
      },
      partner_fees_in: {
        required,
      },
      partner_fees_out: {
        required,
      },
      has_otp: {
        required,
      },
      buy_auto: {
        required,
      },
      sale_auto: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'addCurrencySuccess',
      error: 'addCurrencyError',
      process: 'addCurrencyProcess',
    }),
  },
  watch: {
    success(val) {
      if (val) {
        this.reset()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('payment.title'),
            text: this.$t('payment.success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'admin-settings-allpayment' })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('payment.title'),
            text: this.$t('payment.error'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    progress(val) {
      if (val === 100) {
        this.loadImage = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Upload',
            icon: 'CheckCircleIcon',
            text: 'Fichier uploadé avec succès.',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
  },
  methods: {
    ...mapActions(['addCurrency']),
    async uploadFile(event) {
      const document = Object.values(event.target.files)[0]
      this.loadImage = true
      const self = this
      const storageRef = firebase.storage().ref(`CURRENCIES/${encrypt.encode(this.$store.getters.currentUser.email)}-${uuidv4()}`)
      // Upload file to Firebase Storage
      const uploadTask = storageRef.put(document)
      uploadTask.on('state_changed', snapshot => {
        self.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        if (self.progress < 100) {
          self.loadImage = true
        }
      }, () => {
        this.loadImage = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Upload',
            icon: 'XCircleIcon',
            text: 'Une erreur est survenue lors du téléchargement du fichier.',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(url => {
          this.payment.fileName = url
          this.loadImage = false
        })
      })
    },
    formatter(value) {
      return value.toUpperCase()
    },
    handleFile(e) {
      [this.payment.file] = e.target.files
    },
    next() {
      this.stepper += 1
    },
    prev() {
      this.stepper -= 1
    },
    reset() {
      this.progress = 0
      this.loadImage = false
      this.payment = {
        libelle: null,
        sale: null,
        buy: null,
        sigle: null,
        dev_code: null,
        ismobile: null,
        sale_status: null,
        buy_status: null,
        is_auto: null,
        partner_fees: null,
        partner_percent: null,
        system_percent: null,
        cashin_service_id: null,
        cashout_service_id: null,
        base_currency_symbol: null,
        file: null,
        has_otp: null,
        buy_auto: null,
        sale_auto: null,
        is_crypto: null,
        provider: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    stepOne() {
      this.$v.payment.libelle.$touch()
      this.$v.payment.sigle.$touch()
      this.$v.payment.dev_code.$touch()
      this.$v.payment.file.$touch()
      this.$v.payment.base_currency_symbol.$touch()
      this.$v.payment.sale.$touch()
      this.$v.payment.buy.$touch()
      this.$v.payment.provider.$touch()
      this.$v.payment.fileName.$touch()
      if (this.$v.payment.libelle.$anyError
        || this.$v.payment.sigle.$anyError
        || this.$v.payment.dev_code.$anyError
        || this.$v.payment.file.$anyError
        || this.$v.payment.sale.$anyError
        || this.$v.payment.buy.$anyError
        || this.$v.payment.buy.$anyError
        || this.$v.payment.provider.$anyError
        || this.$v.payment.base_currency_symbol.$anyError
        || this.$v.payment.fileName.$anyError
      ) {
        this.animation = 'animate-wrong'
        setTimeout(() => {
          this.animation = null
        }, 1000)
        return
      }
      this.next()
    },
    stepTwo() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.animation = 'animate-wrong'
        setTimeout(() => {
          this.animation = null
        }, 1000)
        return
      }
      this.tryToSave()
    },

    checkValue(value) {
      return value ? 1 : 0
    },

    tryToSave() {
      this.addCurrency({
        libelle: this.payment.libelle,
        sale: this.payment.sale,
        buy: this.payment.buy,
        sigle: this.payment.sigle,
        fileName: this.payment.fileName,
        dev_code: this.payment.dev_code,
        provider: this.payment.provider,
        ismobile: this.checkValue(this.payment.ismobile),
        sale_status: this.checkValue(this.payment.sale_status),
        buy_status: this.checkValue(this.payment.buy_status),
        is_auto: this.checkValue(this.payment.is_auto),
        partner_percent: this.payment.partner_percent,
        partner_fees_in: this.payment.partner_fees_in,
        partner_fees_out: this.payment.partner_fees_out,
        system_percent: this.payment.system_percent,
        cashin_service_id: this.payment.cashin_service_id,
        cashout_service_id: this.payment.cashout_service_id,
        base_currency_symbol: this.payment.base_currency_symbol,
        has_otp: this.checkValue(this.payment.has_otp),
        buy_auto: this.checkValue(this.payment.buy_auto),
        sale_auto: this.checkValue(this.payment.sale_auto),
        is_crypto: this.checkValue(this.payment.is_crypto),
      })
    },
  },
}
</script>
<style>
.animate-in {
  transform-origin: left;
  animation: in 0.6s ease-in-out;
}

.animate-out {
  transform-origin: bottom left;
  animation: out 0.6s ease-in-out;
}

.animate-wrong {
  animation: wrong 0.4s ease-in-out;
}

@keyframes in {
  0% {
    opacity: 0;
    transform: rotateY(90deg);
  }
  100% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@keyframes out {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  60% {
    transform: rotate(10deg);
  }
  100% {
    transform: translateY(1000px);
  }
}

@keyframes wrong {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(40px);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    transform: translateX(40px);
  }
  80% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
